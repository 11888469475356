.AllValueContainer {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 10px;
  }
  
  .containerValues {
    width: 35%;
  }
  