.TapIcon {
  height: 40px !important;
  width: 40px !important;
  position: absolute;
  right: 5%;
  bottom: 5%;
}
.carta h1 {
  background: #333;
  color: #fff;
  text-align: center;
  margin: 0 0 5% 0;
  padding: 0.5em;
  margin: 0 0 5% 0;
}
.carta img {
  height: 100%;
  width: 100%;
  border-radius: 8px;
}
.carta .cartaQr {
  height: 40% !important;
  width: 50% !important;
}
.carta .isla {
  /* background-color: #222; */
  width: 50px;
  height: 50px;
  position: absolute;
  top: 2.5%;
  left: 2.5%;
  border-radius: 0% !important;
}
.carta .logo {
  /* background-color: #222; */
  width: 100px;
  height: 80px;
  position: absolute;
  top: 0.5%;
  right: 0.5%;
}

.cardBoxCarta {
  float: left;
  font-size: 1.2em;
  margin: 1% 0 0 1%;
  perspective: 800px;
  transition: all 0.3s ease 0s;
  width: 22%;
  margin: 2% 2%;
}
.front::after {
  -moz-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3), -1px 1px 1px rgba(0, 0, 0, 0.2);
  background: none repeat scroll 0 0 #006baa; /* color esquina */
  border-color: #d3cfcf #d8d2d2 #006baa #006baa; /* color de borde */
  border-style: solid;
  border-radius: 0px 8px;
  border-width: 0 50px 50px 0;
  content: "";
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 0;
  transform: rotate(-90deg);
}

.cardBoxCarta:hover .carta {
  transform: rotateY(180deg);
}
.linksInformacionPersonal {
  margin-bottom: 5px;
  justify-content: center;
}
.carta {
  /* background: #222; */
  cursor: default;
  height: 480px;
  width: auto;
  transform-style: preserve-3d;
  transition: transform 0.4s ease 0s;
  width: 100%;
  -webkit-animation: giro 1s 1;
  animation: giro 1s 1;
}

.carta p {
  margin-bottom: 1.8em;
}

.carta .front,
.carta .back {
  backface-visibility: hidden;
  box-sizing: border-box;
  color: white;
  display: block;
  font-size: 1.2em;
  height: 100%;
  padding: 0.8em;
  position: absolute;
  text-align: center;
  width: 100%;
}
.carta .front {
  padding: 0%;
}
.cardBoxCarta .front strong {
  background: #fff;
  border-radius: 100%;
  color: #222;
  font-size: 1.5em;
  line-height: 30px;
  padding: 0 7px 4px 6px;
}

.cardBoxCarta .back {
  transform: rotateY(180deg);
}

.cardBoxCarta .back a {
  padding: 0.3em 0.5em;
  background: #333;
  color: #fff;
  text-decoration: none;
  border-radius: 1px;
  font-size: 0.9em;
  transition: all 0.2s ease 0s;
}

.carta .back a:hover {
  background: #fff;
  color: #333;
  text-shadow: 0 0 1px #333;
}

.cardBoxCarta .carta .back {
  background: rgb(15, 108, 168);
  border-radius: 8px;
  background: #006baa;
}

.back h3 {
  margin-left: 28px;
}

@-webkit-keyframes giro {
  from {
    transform: rotateY(180deg);
  }
  to {
    transform: rotateY(0deg);
  }
}

@keyframes giro {
  from {
    transform: rotateY(180deg);
  }
  to {
    transform: rotateY(0deg);
  }
}
@media (min-width: 750px) and (max-width: 1150px) {
  .cardBoxCarta {
    /* margin-left: 2.8%;
      margin-top: 3%; */
    width: 35%;
  }
}

@media screen and (max-width: 767px) {
  .cardBoxCarta {
    margin-left: 2.8%;
    margin-top: 3%;
    width: 46%;
  }

  .cardBoxCarta:last-child {
    margin-bottom: 3%;
  }
}

@media screen and (max-width: 480px) {
  .cardBoxCarta {
    width: 94.5%;
  }
  .lineaPersonal {
    padding-right: 0% !important;
    margin-left: 2% !important;

    justify-content: flex-end;
    gap: 0px;
  }
}
