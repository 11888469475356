.containerListaMedidores {
  display: flex;
  gap: 5px;
  padding: 30px;
  margin-top: 5%;
  justify-content: center;
  flex-wrap: wrap;
}
.containerMedidor {
  width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.iconMedidor {
  display: flex;
  justify-content: center;
}

.tituloMedidor {
  font-size: 15px;
  text-align: center;
  font-weight: bold;
}

.containerGauge {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.valorGauge {
  font-weight: bold;
  color: #969696;
  font-size: 12px;
  /* Se traladan los titulos con los valores  para que esten alineados */
  transform: translate(0, -20px);
}
.containerGauge span {
  font-size: 20px;
  color: #008000;
  font-weight: bold;
}

.containerMedidor:first-child {
  transform: translate(0, -30px);
}

.containerMedidor:first-child .valorGauge {
  /* Como el primer medidor es mas grande para que cuadren los titulos alineados usamos un traslate diferente  */
  transform: translate(0, 10px);
  font-size: 20px;
}
.containerMedidor:first-child .valorGauge span {
  font-size: 30px;
}
@media (max-width: 550px) {
  .containerListaMedidores {
    flex-direction: row;
  }
  .containerMedidor {
    width: 156px;
  }
}
