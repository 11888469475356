.textoCartaConocenos {
  text-align: justify;
}
.imgCardConocenos {
  height: 15rem !important;
  min-width: 100% !important;
  object-fit: cover !important;
}
@media (min-width: 1700px) {
  .imgCardConocenos {
    height: 20rem !important;
    min-width: 100% !important;
    object-fit: cover !important;
  }
}
@media (max-width: 1400px) {
  .imgCardConocenos {
    height: 10rem !important;
  }
}
@media (max-width: 1000px) {
  .imgCardConocenos {
    height: 8rem !important;
  }
}
@media (max-width: 700px) {
  .card-subtitle-Cono {
    font-size: 10px !important;
  }
  .btnCard {
    font-size: 10px;
  }
  .imgCardConocenos {
    height: 6rem !important;
  }
}
.cardValue {
  height: 35%;
  border-radius: 10px;
  margin: 20px;
  padding: 10px;
  box-shadow: 10px 10px 18px #9d9d9d;
}
.row-divCarta {
  --bs-gutter-x: 0 !important;
}
.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #9d9d9d;
}

.titleSubTitleContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 30px;
}

.titleText {
  font-size: 25px;
  font-weight: bold;
}

.subTitleText {
  color: #9d9d9d;
  font-size: 18px;
}

.text {
  color: #9d9d9d;
}

.btnCard {
  border: 0;
  background: white;
  color: #285a9f;
  font-weight: bold;
}

.justifyText {
  text-align: justify;
}

.rotateDownArrow {
  transform: rotate(90deg);
  transition: 0.7s;
}

.rotateUpArrow {
  transform: rotate(0deg);
  transition: 0.7s;
}

@media screen and (max-width: 600px) {
  .card {
    min-height: 18rem !important;
  }
}

@media screen and (max-width: 600px) {
  .card-text-justifyText {
    font-size: 15px !important;
    color: black;
  }
  .card-subtitle-Cono {
    color: #9d9d9d;
    font-size: 15px !important;
  }
  .card-title-Cono {
    font-size: 20px !important;
  }
  .btnCard {
    font-size: 15px;
  }
}
