.logoFooter {
    width: 164px;
    height: auto;
}

.footerLinks {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: rgb(255, 255, 255);
    text-decoration: none;
}

.informationFooter {
    display: flex;
    align-items: center;
    padding-left: 10px;
    transform: translate(0px, 7px);

}

.footerContainer {
    height: 100%;
    padding-top: 25px;
    background-color:rgba(0, 0, 0, 0.8);

}

.footerCapa {
    background-image: url("../images/imagenFooter.jpg");
}

.titulosFooter{
    text-transform: uppercase;
    font-weight: bold;
    color: white;
}

.linksPoliticas{
    text-decoration: none;
    color: white;
}