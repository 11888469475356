.row-IconGreen {
  background-image: url("./fondoGreen.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.row-movilTitleText {
  display: none !important;
}
.row-movilInfoText {
  display: none !important;
}
.col-InfoGreenText {
  text-align: center;
}
.col-IconGreen {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.col-IconGreenTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.divSectionGreen {
  border-bottom: #008000 2px solid;
  height: 50px;
}
.col-IconGreenTitle h1 {
  padding-top: 5%;
  text-align: center;
  color: white;
}
.col-IconGreensubText h2 {
  padding-top: 5%;
  text-align: center;
  color: white;
}
.row-shadow {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  padding-top: 5%;
  padding-bottom: 5%;
}
.iconSizeTitle {
  transform: rotate(90deg);
  position: relative;
  top: 0%;
}
.iconSize,
.iconSizeTitle {
  font-size: 100px;
}

.col-IconGreen h1,
.col-IconGreen h2 {
  padding-top: 5%;
  text-align: center;
  color: white;
}

.titleSectionGreen {
  color: #008000;
  font-size: 60px;
  font-weight: 150;
  text-align: center;

  padding-bottom: 3%;
}

.titleSectionGreen strong {
  color: #008000;
  font-weight: 600;
  font-size: 80px;
}

.col-InfoGreen {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.col-InfoGreen p {
  padding-top: 5%;
  padding-bottom: 5%;
  text-align: center;
  font-size: 20px;
  padding-right: 2%;
  padding-left: 2%;
}

.row-InfoGreen {
  padding-top: 1%;
  padding-bottom: 3%;
}
.col-IconGreensubText h2 {
  font-size: 20px;
  padding-top: 0;
}

.col-IconGreenTitle h1 {
  font-size: 25px;
  padding-top: 10px;
}
@media screen and (max-width: 991px) {
  .col-IconGreen :first-child {
    margin-top: 10%;
  }

  .col-IconGreensubText h2 {
    font-size: 20px;
    padding-top: 0;
  }

  .col-IconGreenTitle h1 {
    font-size: 25px;
    padding-top: 10px;
  }

  .iconSize {
    font-size: 70px;
  }
  .iconSizeTitle {
    font-size: 80px;
    left: 10%;
  }

  .titleSectionGreen {
    padding-top: 4%;
    padding-bottom: 4%;
    text-align: center;
    font-size: 50px;
  }
  .titleSectionGreen strong {
    font-size: 50px;
  }

  .row-IconGreen {
    background-image: url("./fondoGreenSmall.jpg");
    background-repeat: no-repeat;
    background-size: cover;
  }
  .col-IconGreensubText h2 {
    font-size: 15px;
  }
}

@media screen and (max-width: 750px) {
  .iconSizeTitle {
    display: none;
  }
  .iconSize {
    font-size: 60px;
  }
  .col-IconGreensubText h2 {
    font-size: 15px;
  }
  .col-InfoGreen {
    text-align: center;
  }
  .col-InfoGreenText {
    padding: 0;
    text-align: center;
  }
  .col-InfoGreen h2 {
    font-size: 20px;
  }

  .col-IconGreenTitle h1 {
    font-size: 20px;
    padding-top: 10px;
  }
}

@media screen and (max-width: 700px) {
  .titleSectionGreen,
  .titleSectionGreen strong {
    font-size: 40px;
  }
  .iconSizeTitle {
    display: none;
  }
  .divSectionGreen {
    min-width: 100%;
  }
}
@media screen and (max-width: 575px) {
  .row-normalDiseño {
    display: none !important;
  }
  .row-movilTitleText {
    display: block !important;
  }
  .row-movilTitleText h1 {
    font-size: 22px !important;
  }
  .row-movilTitleText h2 {
    font-size: 17px !important;
  }
  .row-movilInfoText {
    display: block !important;
  }
  .row-infoGreenText,
  .row-infoGreenTitle {
    display: none !important;
  }
}
