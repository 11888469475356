.videoContainer {
  min-width: 100%;
  height: 70vh;
  overflow: hidden;
  padding: 0%;
  margin-top: 0;
}

.videoContainer video {
  min-width: 100%;
  min-height: 100%;
}

.infoCanarias {
  color: #eeb927;
}

/* .divTapa {
  position: absolute;

  z-index: 5;
  min-width: 100%;
  min-height: 70%;
} */
