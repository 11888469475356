.CaracteristicasConocenos {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 5px;
  flex-wrap: wrap;
}

.ConocenosCartaCaracteristicas {
  background-color: aliceblue;
  width: 200px;
  padding: 20px;
  min-height: 250px;
  border-radius: 10px;
}

.TituloCaracteristicasConocenos {
  padding-top: 3%;
  color: #285a9f;
  text-transform: uppercase;
  font-size: 60px;
  text-align: center;
}

.ConocenosCartaCaracteristicas h2 {
  text-align: center;
  color: #285a9f;
}

.ConocenosCartaCaracteristicas p {
  text-align: center;
}

.ConocenosCartaCaracteristicas p strong {
  display: block;
}

.activarScrollConocenosCard {
  animation-name: LeftEnter;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

@keyframes LeftEnter {
  from {
    left: 0px;
  }

  to {
    left: 200px;
  }
}

@media (max-width: 1230px) {
  .CaracteristicasConocenos > div {
    transition: none !important;
    transform: none !important;
  }
}
@media (max-width: 600px) {
  .CaracteristicasConocenos {
    padding-top: 5%;
  }
  .ConocenosCartaCaracteristicas {
    width: 188px;
  }
}
