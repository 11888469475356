.divTextCompleto {
  padding-top: 7px;
}
.divText1Portada {
  text-align: center;
  margin-bottom: 20px;
}
.divText2Portada {
  text-align: center;
}
.divText1Portada h2 {
  font-size: 14px;
  font-weight: bold;
}
.divText2Portada h3 {
  font-size: 13px;
  color: #767575;
}
.activeTextoPortada {
  animation-duration: 1s;
  animation-name: slidein;
  animation-iteration-count: 1;
}
@keyframes slidein {
  from {
    transform: Scale(0);
  }
  to {
    transform: Scale(1);
  }
}
@media screen and (min-width: 830px) {
  .divText1Portada h2 {
    font-size: 18px;
  }
  .divText2Portada h3 {
    font-size: 14px;
  }
}
@media screen and (min-width: 1024px) {
  .divText1Portada h2 {
    font-size: 18px;
  }
  .divText2Portada h3 {
    font-size: 16px;
  }
}
@media screen and (min-width: 1440px) {
  .divText1Portada h2 {
    padding-top: 25px;
    font-size: 28px;
  }
  .divText2Portada h3 {
    font-size: 26px;
  }
}
@media screen and (min-width: 2500px) {
  .divText1Portada {
    padding-top: 40px;
  }
  .divText1Portada h2 {
    font-size: 40px;
  }
  .divText2Portada h3 {
    font-size: 38px;
  }
}

@media (max-width: 1230px) {
  .divTextCompleto {
      transition: none !important;
      transform: none !important;
  }
}