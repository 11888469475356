@import url("https://fonts.googleapis.com/css2?family=Libre+Franklin:wght@900&display=swap");
.imgPortada {
  height: 950px;
  width: 100%;
}
.alice-carousel {
  height: 775px;
  width: 100%;
  margin-left: 0% !important;
  overflow: hidden;
  z-index: 0;
}
.sectionCarruselPortada {
  width: 100%;
  background-color: #e2e3e1;
  display: flex;
  flex-direction: row;
  position: relative;
}
.imgLogoPortada {
  width: 370px;
  height: 300px;
}
.divImagenLogo {
  position: absolute;
  top: 100%;
  width: 50%;
  display: flex;
  background-color: rgba(0, 0, 0, 0.4);
  flex-direction: column;
  position: absolute;
  justify-content: center;
  align-items: center;
  transform: translate(100%, -100%);
  border-radius: 4em;
  border-top-left-radius: 250px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  padding-bottom: 4%;
  padding-top: 3.5%;
  z-index: 6;
}
.titlePortada {
  width: 100%;
  font-family: "Libre Franklin";
  color: rgb(240, 240, 240);
  text-align: center;
  font-size: 60px;
}

.titlePortada span {
  display: block;
  color: #eeb927;
}

@media (max-width: 1500px) {
  .titlePortada {
    font-size: 40px;
  }
}
@media (max-width: 1050px) {
  .titlePortada {
    font-size: 40px;
    padding-left: 25%;
  }
  .alice-carousel {
    height: 400px;
  }
  .imgPortada {
    height: 400px;
  }
}
@media (max-width: 850px) {
  .divImagenLogo {
    display: none;
  }
  .alice-carousel {
    height: 400px;
  }
  .imgPortada {
    height: 400px;
  }
}
