@import url("https://fonts.googleapis.com/css2?family=Libre+Franklin:wght@900&display=swap");
.InfoTitleTextContainer {
  width: 50%;
  display: flex;
  background-color: rgba(0, 0, 0, 0.4);
  flex-direction: column;
  position: absolute;
  justify-content: center;
  align-items: center;
  transform: translate(100%, -100%);
  border-radius: 4em;
  border-top-left-radius: 250px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  padding-bottom: 4%;
  padding-top: 3.5%;
}

.InfoTextTitle {
  font-size: 50px;

  font-family: "Libre Franklin";
  color: white;
}

@media screen and (max-width: 1900px) {
  .InfoTextTitle {
    font-size: 45px;
  }
}

@media screen and (max-width: 1700px) {
  .InfoTextTitle {
    font-size: 40px;
  }
}

@media screen and (max-width: 1420px) {
  .InfoTextTitle {
    font-size: 25px;
  }
}

.InfoText {
  font-size: 23px;
  color: white;
  /* color: #F6BE32; */
}

@media screen and (max-width: 1400px) {
  .InfoText {
    font-size: 15px;
  }
}
@media screen and (max-width: 991px) {
  .InfoTitleTextContainer {
    background-color: rgba(0, 0, 0, 0);
  }
}

@media screen and (max-width: 1400px) {
  .divLogo {
    height: 12vh;
  }
  .imagen {
    width: 12vh;
    height: 12vh;
  }
}

@media screen and (max-width: 991px) {
  .divLogo {
    height: 9vh;
  }
  .imagen {
    width: 9vh;
    height: 9vh;
  }
}

@media screen and (max-width: 991px) {
  .InfoTitleTextContainer {
    transform: translate(50%, -100%);
    text-align: center;
  }
}
