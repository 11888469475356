.columna{
    height: 700px;
    display: flex; 
    align-content: center;
    justify-content: center;
}

@media screen and (max-width: 991px) {
    .columna {
        height: min-content;
    }
  }