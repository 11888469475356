@import url("https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400;500&display=swap");

.fondoPersonal {
  background-image: url("../image/fondoSection.png");
  min-width: 100%;
  min-height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}
/* Fila donde se despliega las cartas del Personal */
.lineaPersonal {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 2%;
}
.title {
  display: flex;
  flex-direction: column;
  align-items: center;

  justify-content: space-around;
  margin-bottom: 1%;
}

.titleFirst {
  text-align: center;
  color: #285a9f;
  font-size: 75px;
  font-weight: bold;
}

.titleSecond {
  text-align: center;
  color: transparent;
  font-size: 55px;
  font-weight: bold;
  -webkit-text-stroke: 2px #285a9f;
}

.titleThird {
  color: transparent;
  font-size: 55px;
  font-weight: bold;
  -webkit-text-stroke: 2px #285a9f;
  transform: translate(0%, -25%);
}

.imagenPersonal {
  border-radius: 10%;
  border: 3px solid #ffffff;
  max-width: 400px;
}

.informacionPersonal p {
  margin-bottom: 0.1rem;
}

.informacionPersonal {
  margin-top: 1%;
  width: 95%;
  height: 110px;
  border-radius: 3%;
  color: white;
  font-family: "Maven Pro", sans-serif;
  transform: translate(0, -120px);
  font-weight: bold;
}
.socialInformacionPersonal {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: 8px;
  width: 90%;
  font-size: 11px;
  margin-top: 3px;
}
.socialInformacionPersonal p {
  margin-right: 15px;
}

.trabajoPersonal {
  font-size: 14px;
  font-weight: bold;
  padding-left: 5%;
}

.nombrePersonal {
  font-size: 20px;
  padding-left: 5%;
  font-weight: none;
}

.islaPersonal {
  font-size: 15px;
  padding-left: 5%;
}
.TituloCartaRet {
  position: absolute;
  top: 5%;
  left: 50%;
  /*Para centrar el texto*/
  transform: translateX(-50%);
  font-size: 85%;
  color: #285a9f;
}
.linea {
  border-top: 1px solid white;
  height: 2px;
  width: 90%;
  padding: 0;
  margin: 3px auto 0px auto;
}
.callLink {
  display: flex;
  flex-direction: row;
  margin-left: 10px;
  cursor: pointer;
  transition: color 0.5s ease;
}

.emailLink {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  transition: color 0.5s ease;
}

.linksInformacionPersonal {
  display: flex;
  margin-top: 3px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  font-size: 10px;
  margin-left: 15px;
}

.inIcon:hover {
  background-color: #285a9f;
}

.twIcon:hover {
  background-color: #285a9f;
}

.callLink:hover {
  color: #285a9f;
}

.emailLink:hover {
  color: #285a9f;
}
