.containerTextSection {
  padding-left: 10%;
  padding-top: 10%;
}

.titleFirst {
  color: #285a9f;
  font-size: 75px;
  font-weight: bold;
}

.titleSecond {
  color: transparent;
  font-size: 75px;
  font-weight: bold;
  -webkit-text-stroke: 2px #285a9f;
  transform: translate(0px, -20px);
}

.iconsContainer {
  padding-top: 1%;
  padding-bottom: 3%;
  display: flex;
  flex-direction: column;
  margin-left: 5%;
  gap: 5px;
}

.information {
  margin-left: 5px;
  transform: translate(0px, 10px);
}

.informationModified {
  margin-left: 5px;
  transform: translate(0px, 5px);
}

.test {
  margin-bottom: 0.5em;
}

.singleIconContainer {
  display: flex;
  align-items: center;
  transition: color 0.5s ease;
}
.titleFormContainer {
  display: flex;
  flex-direction: column;
}

.links {
  text-decoration: none;
  color: black;
  margin-left: 5px;
  padding: 5px;
}

.links:hover {
  color: #285a9f;
}

@media screen and (max-width: 992px) {
  .titleFormContainer {
    justify-content: center;
    text-align: center;
  }
}

@media screen and (max-width: 700px) {
  .titleFirst {
    font-size: 50px;
  }
  .titleSecond {
    font-size: 50px;
  }
}

@media screen and (max-width: 500px) {
  .titleFirst {
    font-size: 40px;
  }
  .titleSecond {
    font-size: 40px;
  }
  .titleFormContainer {
    justify-content: center;
    text-align: center;
  }
}
