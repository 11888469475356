/* .app {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  justify-content: center;
  align-items: center;
}
header {
  position: sticky;
}
main {
  position: relative;
  top: 0;
} */

body {
  background-color: #e2e3e1;
}
