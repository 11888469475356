.containerImage {
  width: 100%;
  padding: 20;
  justify-content: space-evenly;
  align-items: center;
  display: flex;
}
.containerIslands {
  width: 50%;
}

.buttonTextContainer {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mapaTitle {
  font-size: 50px;
  color: #ffffff;
}

.containerMapaTitle {
  width: 100%;
  display: flex;
  justify-content: center;
}

.containerMapAll {
  width: 100%;
  display: flex !important;
  padding-bottom: 2%;
  background-color: #285a9f;
  border-radius: 30;
}

.containerMap {
  min-width: 100%;
  min-height: 30vh;
}

.styleColumn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.styleButton {
  background-color: #285a9f;
  width: 75%;
  margin: 10px 0 10px 0;
}

.titleImage {
  font-size: 20px;
  color: white;
}

.containerTitleImage {
  background-color: #285a9f;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.containerImageMap{
  height: 45vh; 
  position: relative;
}

.filaMovil {
  display: none !important;
}

.img-fluid {
  max-width: 95% !important;
  margin: auto !important;
  display: flex;
}

@media screen and (max-width: 912px) {
  .containerImage {
    display: none;
  }

  .containerImageMap{
    height: 30vh !important;
  }

  .containerTitleImage {
    width: 100%;
  }

  .img-fluid {
    max-width: 73% !important;
  }

  .leaflet-container {
    width: 100%;
    height: 28vh !important;
  }

  .containerMap {
    width: 90%;
    height: 30vh;
  }

  .filaPC {
    display: none;
  }

  .filaMovil {
    display: block;
  }

  .styleColumn {
    margin: 10px;
  }

  .styleButton {
    width: 35%;
  }

  .row>*{
    margin-top: 1.5vh !important;
  }
}

@media screen and (max-width: 450px) {
  .styleButton {
    width: 90%;
    margin: 0;
  }
}

@media screen and (max-width: 1200px) {
  .styleButton {
    width: 100%;
  }
}
