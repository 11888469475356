.title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 5%;
    
}

.titleFirst {
    color: #006baa;
    font-size: 75px;
    font-weight: bold;
    
}

.titleSecond {
    color: transparent;
    font-size: 75px;
    font-weight: bold;
    -webkit-text-stroke: 2px #006baa;
    transform: translate(0, -25px);
}

.textDescription{
    font-weight: bold;
    font-size: 15px;
}