.imgLogoCola {
  width: 20%;
  height: 170px;
  margin-bottom: 30%;
}

.col-ColMarca {
  border-right: 5px solid #767575;
  margin-bottom: 50px !important;
}

.row-divRow {
  width: 100%;
  padding-top: 5%;
  padding-left: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ColaTestTitle {
  color: gray;
  font-size: 70px;
  text-align: center;
  font-family: "Libre Franklin" !important;
}

.fila {
  min-width: 100%;
  height: 100%;
  padding-bottom: 2%;
}

.colMarcas {
  display: flex;
  justify-content: flex-start;
  height: 50%;
  align-content: center;
  margin-bottom: 3%;
}

.contenedorMarcas {
  height: 200px;
  width: 60%;
  min-width: 950px;
  background-color: white;
  margin-left: 20px;
  display: flex;
  justify-content: flex-end;
}

.contenedorLogosMarcas {
  min-width: 100%;
  gap: 25px;
  display: flex;
  min-height: 100%;
  align-items: center;
  justify-content: flex-end;
  transform: translate(-30px, 0px);
}

.cajaLogosMarcas {
  width: 15%;
  display: flex;
  justify-content: center;
}

.circuloMarcas {
  min-width: 230px;
  height: 230px;
  border-radius: 8rem;
  background-color: grey;
  transform: translate(80px, -15px);
  display: flex;
  align-items: center;
}

.tituloMarcas {
  text-align: center;
  color: white;
}

.imgLogoLaguna {
  width: 350px;
  height: 100px;
}

.imgLogoFSV {
  width: 100px;
  height: 100px;
}

.imgLogoCocaCola {
  width: 150px;
  height: 100px;
}

.imgLogoUniEuro {
  width: 100px;
  height: 120px;
}

.imgLogoAbi {
  width: 220px;
  height: 60px;
  margin-left: 40px;
}
.row {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.colColaboraciones {
  display: flex;
  justify-content: flex-end;
  height: 50%;
  align-content: center;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.circuloColaboraciones {
  width: 230px;
  height: 230px;
  border-radius: 8rem;
  background-color: grey;
  transform: translate(80px, -15px);
  display: flex;
  align-items: center;
}

.tituloColaboraciones {
  text-align: center;
  color: white;
  font-size: 35px;
  transform: translate(10px, 0px);
}

.contenedorColaboraciones {
  height: 200px;
  width: 60%;
  min-width: 900px;
  background-color: white;
  margin-right: 20px;
  display: flex;
  justify-content: center;
}

.contenedorLogosColaboraciones {
  max-width: 80%;
  gap: 25px;
  display: flex;
  min-height: 100%;
  align-items: center;
  justify-content: space-evenly;
  transform: translate(30px, 0px);
}

.cajaLogosColaboraciones {
  width: 15%;
  display: flex;
  justify-content: center;
}

.cajaLogosColaboracionesPalmas {
  width: 15%;
  display: flex;
  justify-content: center;
  transform: translate(20px, 0px);
}

.imgLogoGobierno {
  width: 220px;
  height: 60px;
}

.imgLogoPalmas {
  width: 120px;
  height: 120px;
}

.imgLogoEuropea {
  height: 80px;
}

.imgLogoLaguna {
  height: 40px;
}

.col-divCol {
  display: flex;
  justify-content: center;
  align-items: center;
}

.imgLogoGobierno {
  width: 700px;
}

@media (max-width: 1150px) {
  .colMarcas,
  .colColaboraciones {
    flex-basis: 50% !important;
    max-width: 50% !important;
    display: flex !important;
    justify-content: center !important;
  }

  .colColaboraciones {
    display: flex !important;
    justify-content: center !important;
    flex-direction: column !important;
    height: 100%;
    align-items: center;
  }

  .contenedorMarcas {
    height: 900px;
    width: 200px;
    min-width: 200px;
  }

  .contenedorColaboraciones {
    height: 900px;
    width: 200px;
    min-width: 200px;
    position: absolute;
    transform: translate(0px, 450px);
  }

  .contenedorLogosMarcas {
    transform: translate(230px, 80px) !important;
    flex-direction: column;
    height: 80%;
    justify-content: space-evenly;
  }

  .contenedorLogosColaboraciones {
    flex-direction: column;
    height: 80%;
    transform: translate(0px, 90px);
  }

  .contenedorLogosMarcas {
    transform: translate(230px, 0px);
    justify-content: space-evenly;
  }

  .circuloMarcas {
    transform: translate(15px, -100px);
  }

  .imgLogoAbi {
    margin-left: 0;
    width: 160px;
    height: 40px;
  }

  .imgLogoPalmas {
    transform: translate(-15px, 0px);
  }

  .circuloColaboraciones {
    transform: translate(-10px, 10px);
    position: absolute;
    z-index: 2;
  }

  .imgLogoGobierno {
    width: 180px;
    height: 40px;
  }
}

@media (max-width: 700px) {
  .colMarcas,
  .colColaboraciones {
    flex-basis: 50% !important;
    max-width: 50% !important;
    display: flex !important;
    justify-content: center !important;
  }

  .colColaboraciones {
    display: flex !important;
    justify-content: center !important;
    flex-direction: column !important;
    height: 100%;
    align-items: center;
  }

  .contenedorMarcas {
    height: 500px;
    width: 150px;
    min-width: 150px;
  }

  .contenedorColaboraciones {
    height: 500px;
    width: 150px;
    min-width: 150px;
    position: absolute;
    transform: translate(0px, 250px);
  }

  .contenedorLogosMarcas {
    flex-direction: column;
    height: 80%;
    justify-content: space-evenly;
  }

  .contenedorLogosColaboraciones {
    flex-direction: column;
    height: 80%;
    transform: translate(0px, 40px);
    gap: 0px;
  }

  .contenedorLogosMarcas {
    transform: translate(155px, 25px) !important;
    justify-content: space-evenly;
  }

  .circuloMarcas {
    transform: translate(5px, -100px);
    height: 160px;
    width: 160px;
    min-width: 160px;
  }

  .imgLogoFSV {
    width: 100px;
    height: 60px;
  }

  .imgLogoCocaCola {
    width: 90px;
    height: 60px;
  }

  .imgLogoUniEuro {
    width: 70px;
    height: 90px;
  }

  .imgLogoAbi {
    margin-left: 0;
    width: 90px;
    height: 21px;
  }

  .imgLogoPalmas {
    transform: translate(-15px, 0px);
    width: 90px;
    height: 90px;
  }

  .imgLogoUniEuro {
    height: 65px;
  }

  .imgLogoGobierno {
    width: 130px;
    height: 35px;
  }

  .imgLogoLaguna {
    height: 35px;
  }

  .circuloColaboraciones {
    min-width: 160px;
    width: 160px;
    height: 160px;
    transform: translate(-10px, -20px);
  }

  .tituloColaboraciones,
  .tituloMarcas {
    font-size: 25px;
  }
  .containerMarcasGlobal {
    padding-top: 25% !important;
  }
}

@media (max-width: 1150px) {
  .containerMarcasGlobal {
    padding-top: 15% !important;
  }
}

@media (max-width: 500px) {
  .containerMarcasGlobal {
    padding-top: 35% !important;
  }
}

@media (min-width: 1000px) {
  .containerMarcasGlobal {
    height: 100% !important;
  }
}

@media (max-width: 649px) {
  .containerMarcasGlobal {
    height: 100% !important;
    padding-top: 25% !important;
  }
}
