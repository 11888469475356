.BodyTransparencia {
  background-image: url("../images/FondoDetail.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  height: min-content;
}
.bodyInfoTrans {
  padding-top: 5%;
  padding-left: 2%;
  padding-right: 2%;
}
.bodyText p {
  font-size: 20px;
  text-align: justify;
}
.pageTitle {
  padding-top: 5%;
  width: 100%;
  height: 10vh;
}
.divInfoArch {
  padding-top: 5%;
  padding-left: 5%;
  padding-right: 5%;
}
.h2Pag2 {
  position: relative;
  font-size: 35px;
  bottom: 9vh;
  margin-left: 25%;
  line-height: 4vh;
  color: white;
}
.cardTransparencia .imgPag2 {
  width: 150px;
  height: 100%;
}
.pageTitle h2 {
  color: black;
  position: relative;
  top: 50%;
  transform: translate(0, -50%);
  padding: 10px;
  text-align: center;
  justify-content: center;
  font-size: 55px;
}
.row {
  justify-content: center;
}
.Principal h3 {
  font-size: 22px;
  text-align: justify;
  margin-bottom: 5%;
}
bodyText h3 {
  line-height: 2rem;
  color: rgb(15, 108, 168);
  font-size: 20px;
}
.FilaTrasnparencia {
  align-items: center;
  padding-top: 60px;
}

@media screen and (max-width: 1400px) {
  .BodyTransparencia {
    background-image: url("../images/FondoDetail.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 120vh;
  }
  .cardTransparencia .h2Pag2 {
    font-size: 27px !important;
    bottom: 8vh !important;
    line-height: 3vh !important;
  }
  .cardTransparencia .h2pag1,
  .cardTransparencia-2 .h2pag1 {
    font-size: 27px !important;
  }
  .cardTransparencia h2 {
    margin-left: 30%;
  }
  .cardTransparencia .imgPag1,
  .cardTransparencia-2 .imgPag1 {
    width: 110px;
  }
  .cardTransparencia-2 .imgPag1 {
    margin-left: 70%;
  }
  .pageTitle h2 {
    font-size: 40px;
  }
}

@media screen and (max-width: 991px) {
  .cardTransparencia .h2Pag2 {
    font-size: 20px !important;
    bottom: 6.5vh !important;
    line-height: 2vh !important;
  }
  .cardTransparencia .h2pag1 {
    margin-left: 40%;
  }
  .cardTransparencia .imgPag1,
  .cardTransparencia-2 .imgPag1 {
    width: 200px;
  }
  .BodyTransparencia {
    background-image: url("../images/FondoDetail.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 140vh;
  }
  .FilaTrasnparencia {
    padding-top: 30px;
  }
  .pageTitle h2 {
    font-size: 45px;
  }
  .FilaTrasnparencia h3 {
    font-size: 20px;
  }
  .pageTitle {
    margin-bottom: 0.5%;
  }
  .pageTitle h2 {
    font-size: 40px;
  }
  .bodyText p {
    font-size: 15.5px;
  }
}
@media screen and (max-width: 767px) {
  .BodyTransparencia {
    background-image: url("../images/FondoDetail.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
  }
  .cardTransparencia .h2Pag2 {
    font-size: 17px !important;
    bottom: 6.5vh !important;
    line-height: 2vh !important;
    text-align: center;
  }

  .cardTransparencia .h2pag1,
  .cardTransparencia-2 .h2pag1 {
    font-size: 30px;
  }
  .cardTransparencia .h2pag1 {
    margin-left: 40%;
  }
  .cardTransparencia-2 {
    padding-left: 15%;
  }
  .cardTransparencia .imgPag1,
  .cardTransparencia-2 .imgPag1 {
    width: 150px;
  }
  .cardTransparencia {
    margin-top: 5px;
  }
  .Principal {
    position: relative;

    transform: translateY(10%);
  }
  .FilaTrasnparencia {
    padding-top: 0px;
  }
  .pageTitle {
    margin-bottom: -8%;
  }
  .pageTitle h2 {
    font-size: 40px;
  }
  .FilaTrasnparencia h3 {
    font-size: 20px;
  }
  .pageTitle {
    margin-bottom: 0.5%;
  }
  .pageTitle h2 {
    font-size: 30px;
  }
  .bodyText p {
    font-size: 13.5px;
  }
}
@media screen and (max-width: 550px) {
  .BodyTransparencia {
    height: 100vh;
    padding-right: 5%;
  }
  .Principal {
    transform: translate(0%, 0%);
  }
  .cardTransparencia,
  .cardTransparencia-2 {
    height: auto;
    min-width: 90vw;

    height: 100px !important;
    background: gray !important;
    border: 1px solid black !important;
    margin-bottom: 2vh !important;
  }
  .cardTransparencia-2 {
    padding-left: 8%;
  }
  .cardTransparencia .h2pag1 {
    position: relative !important;
    margin-left: 40% !important;
    font-size: 25px !important;
  }
  .cardTransparencia-2 .h2Pag1 {
    font-size: 25px;
  }
  .Principal h3 {
    font-size: 13px;
  }
  .pageTitle h2 {
    font-size: 35px;
  }
  .cardTransparencia-2 .imgPag1 {
    margin-left: 65%;
  }

  .FilaTrasnparencia {
    padding-top: 30px;
  }
  .cardTransparencia .h2Pag2 {
    font-size: 13px !important;
    bottom: 5.5vh !important;
    margin-left: 36%;
    text-align: center;
  }
  .pageTitle {
    margin-bottom: 0.5%;
  }
  .pageTitle h2 {
    font-size: 30px;
  }
  .bodyText p {
    font-size: 13.5px;
  }
}
@media screen and (max-width: 435px) {
  .cardTransparencia .h2pag1,
  .cardTransparencia-2 .h2pag1 {
    font-size: 20px !important;
  }
  .cardTransparencia .imgPag1,
  .cardTransparencia-2 .imgPag1 {
    width: 100px;
  }
  .cardTransparencia .h2Pag2 {
    font-size: 12px !important;
    margin-left: 32%;
    text-align: center;
  }
  .cardTransparencia .imgPag2 {
    width: 100px;
  }

  .bodyText p {
    font-size: 12.5px;
  }
  .pageTitle {
    margin-bottom: 0.5%;
  }
  .pageTitle h2 {
    font-size: 30px;
  }
}
