.divSectionCertificados {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.divCertificados {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 5%;
  padding-bottom: 5%;
  transition: transform 0.2s;
}
/* .activeIconosPortada {
  animation-duration: 1s;
  animation-name: slidein;
  animation-iteration-count: 1;
}
@keyframes slidein {
  from {
    transform: Scale(0);
  }
  to {
    transform: Scale(1);
  }
} */
.imgCerti {
  width: 170px;
  height: 150px;
}
@media screen and (min-width: 830px) {
  .imgCerti {
    width: 200px;
    height: 170px;
  }
}
@media screen and (min-width: 970px) {
  .imgCerti {
    width: 150px;
    height: 130px;
  }
  .divSectionCertificados {
    gap: 35px;
    padding-left: 5%;
    padding-right: 5%;
  }
}
@media screen and (min-width: 1440px) {
  .imgCerti {
    width: 230px;
    height: 170px;
  }
}

.divCertificados:hover {
  transform: scale(1.1);
}



@media (max-width: 1230px) {
  .divSectionCertificados {
      transition: none !important;
      transform: none !important;
  }
}