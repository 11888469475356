.headerComponent {
  width: 100%;
  display: flex;
  background-color: rgba(0, 0, 0, 0.7);
  min-height: 5vh;
  align-items: center;
  justify-content: space-between;
}

.colorItem {
  display: flex;
  color: white;
  align-items: center;
  padding-right: 10px;
  text-decoration: none;
}

.information-Header {
  display: flex;
  align-items: center;
  padding-top: 15px;
  padding-left: 10px;
}
.informationIcon :hover {
  color: #285a9f;
}

.informationIcon {
  width: 70%;
  display: flex;
  padding-left: 20px;
}

.socialIcon {
  width: 30%;
  display: flex;
  justify-content: flex-end;
  color: white;
  align-items: center;
  padding-right: 2%;
  gap: 20px;
}
.socialIcon :hover {
  color: #285a9f;
}
.socialIconItems {
  color: white;
}

@media screen and (max-width: 1270px) {
  .information-Header {
    display: none;
  }
  .informationIcon {
    gap: 15px;
  }
}

@media screen and (min-width: 1670px) {
  .informationIcon {
    padding-left: 1%;
    gap: 30px;
  }
  .information-Header {
    font-size: 18px;
  }
  .socialIcon {
    gap: 30px;
  }
}
