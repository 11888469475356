.cardValue {
    height: 35%;
    border-radius: 10px;
    margin: 20px;
    padding: 10px;
    box-shadow: 10px 10px 18px #9d9d9d;
  }

  .card-title{
    font-size: 1.25rem !important;
    font-weight: bold;
  }
  
  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #9d9d9d;
  }
  
  .titleSubTitleContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 30px;
  }

  .titleText {
    font-size: 25px;
    font-weight: bold;
  }
  
  .subTitleText {
    color: #9d9d9d;
    font-size: 18px;
  }
  
  .text {
    color: #9d9d9d;
  }
  
  .btnCard {
    border: 0;
    background: white;
    color: #006baa;
    font-weight: bold;
  }
  
  .justifyText {
    text-align: justify;
  }
  
  .rotateDownArrow {
    transform: rotate(90deg);
    transition: 0.7s;
  }
  
  .rotateUpArrow {
    transform: rotate(0deg);
    transition: 0.7s;
  }
  
  .maquinaTitle {
    text-align: center;
  }
  @media (max-width: 1100px) {
    .card-title {
      font-size: 1rem; /* Tamaño de fuente deseado */
    }
  }

  @media screen and (max-width: 600px) {
    .card {
      min-height: 18rem !important;
    }
  }
  
  @media screen and (max-width: 768px) {
    .maquinaTitle {
      font-size: 15px;
    }
  }
  
  @media screen and (max-width: 768px) {
    .maquinaTitle {
      font-size: 15px;
    }
  }
  
  
  @media screen and (max-width: 550px) {
    .maquinaTitle {
      font-size: 10px;
    }
  }