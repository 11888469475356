.ConocenosContainer {
  margin: 5px;
}
.displayCono {
  display: none;
}
.cartaConocenos {
  margin-top: 20%;
}
.FondoGeneral {
  background-image: url("../images/FondoGeneral.png");
  background-repeat: no-repeat;
  background-size: contain;
}
.iconoFlecha {
  font-size: 70px;
}
.iconSizeHis {
  font-size: 70px !important;
}
.col-timeConon {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-bottom: 5%;
}
.row-timeConon {
  background-color: gray;
  padding-top: 13.5%;
  padding-bottom: 13.5%;
  padding-right: 8%;
  padding-left: 8%;
  border-radius: 8rem !important;
}
.lineTime {
  border-bottom: 2px solid #285a9f;
  width: 50%;
  transform: rotateY(-180deg);
}
.row-timeConon h2 {
  text-align: center;
  color: white;
  font-size: 25px;
}
.Conocenos {
  width: 100% !important;
  margin-left: 0% !important;
  margin-right: 0% !important;

  background-size: cover;
}
.titleSection div {
  margin-right: 15px;
}
.titleSection {
  margin-top: 20px;
}
.SectionHistoria {
  width: 100%;
  /* background-image: url("../images/fondo2.png");
  background-repeat: no-repeat;
  background-size: cover; */
  padding-left: 2%;
  padding-bottom: 2%;
}
.row-iconosConocenos {
  padding-bottom: 45%;
}
.col-iconosConocenos {
  padding-top: 5%;
}
.row-cardHistoria {
  padding-top: 5%;
  padding-bottom: 2.5%;
  background-color: #e4e5e0;
  margin-bottom: 20%;
  padding-right: 7%;
  padding-left: 2%;
  border-bottom-right-radius: 4em;
  border-top-right-radius: 4em;
}
@media (min-width: 1750px) {
  .row-timeConon {
    margin-bottom: 2.5%;
    padding-right: 10%;
    padding-left: 10%;
  }
  .iconSizeHis {
    font-size: 100px !important;
  }
  .row-cardHistoria h6 {
    font-size: 25px;
  }
  .row-cardHistoria h4 {
    font-size: 25px !important;
  }
}
@media (max-width: 1400px) {
  .row-timeConon {
    margin-bottom: 0% !important;
    padding-right: 5%;
    padding-left: 5%;
  }
  .iconSizeHis {
    font-size: 80px !important;
  }
  .row-cardHistoria h6 {
    font-size: 20px;
  }
  .row-cardHistoria h4 {
    font-size: 17px !important;
  }
  .row-iconosConocenos {
    margin-bottom: 15%;
  }
}
@media (max-width: 1000px) {
  .row-timeConon {
    margin-bottom: 10% !important;
    padding-right: 2.5%;
    padding-left: 2.5%;
  }
  .col-timeConon {
    padding-bottom: 10% !important;
  }
  .iconSizeHis {
    font-size: 50px !important;
    margin-bottom: 40%;
  }
  .row-cardHistoria {
    margin-bottom: 35%;
  }
  .row-cardHistoria h6 {
    font-size: 13px;
  }
  .row-cardHistoria h4 {
    font-size: 15px !important;
  }
  .row-timeConon h2 {
    text-align: center;
    color: white;
    font-size: 20px;
  }
}
@media (max-width: 850px) {
  .row-timeConon {
    margin-bottom: 15% !important;
    padding-right: 2%;
    padding-left: 2%;
  }
  .col-timeConon {
    padding-bottom: 10% !important;
  }
  .iconSizeHis {
    font-size: 50px !important;
    margin-bottom: 40%;
  }
  .row-cardHistoria {
    margin-bottom: 35%;
  }
  .row-cardHistoria h6 {
    font-size: 10px;
  }
  .row-cardHistoria h4 {
    font-size: 12px !important;
  }

  .row-timeConon h2 {
    text-align: center;
    color: white;
    font-size: 17px;
  }
  .iconoFlecha {
    font-size: 60px;
  }
}
@media (max-width: 750px) {
  .row-timeConon {
    margin-bottom: 12% !important;
    padding-right: 1%;
    padding-left: 1%;
    padding-top: 14%;
  }
  .row-cardHistoria {
    margin-bottom: 30%;
  }
  .col-timeConon {
    padding-bottom: 5% !important;
  }
  .iconSizeHis {
    font-size: 45px !important;
  }
  .iconoFlecha {
    font-size: 60px;
  }
}
@media (max-width: 700px) {
  .row-timeConon {
    margin-bottom: 10% !important;
    padding-right: 0%;
    padding-left: 0%;
    padding-top: 18% !important;
  }
  .col-timeConon {
    padding-bottom: 5% !important;
  }
  .iconSizeHis {
    font-size: 40px !important;
  }
  .iconoFlecha {
    font-size: 60px;
  }
  .row-cardHistoria h6 {
    font-size: 8px;
  }
  .row-cardHistoria h4 {
    font-size: 10px !important;
  }
  .col-iconosConocenos {
    padding-top: 10% !important;
  }
}
@media (max-width: 600px) {
  .row-timeConon {
    margin-bottom: 2% !important;
    padding-right: 0%;
    padding-left: 0%;
    padding-top: 20%;
  }
  .col-timeConon {
    padding-bottom: 12% !important;
  }
  .iconSizeHis {
    font-size: 30px !important;
    margin-bottom: 60%;
  }
  .col-iconosConocenos {
    padding-top: 18%;
  }
  .iconoFlecha {
    font-size: 60px;
  }
  .row-cardHistoria h6 {
    font-size: 8px;
  }
  .row-cardHistoria h4 {
    font-size: 10px !important;
  }
}
.col-infoHisConocenos {
  padding-top: 3%;
}
.row-cardHistoria h6 {
  text-align: end;
  color: gray;
}
.row-cardHistoria h4 {
  text-align: start;
  font-size: 20px;
}
@media (max-width: 575px) {
  .col-iconosConocenos {
    display: none !important;
  }
  .row-cardHistoria {
    margin-bottom: 10%;
  }

  .row-timeConon {
    background-color: transparent;
    padding-top: 5%;
    padding-bottom: 0% !important;
  }
  .row-timeConon h2 {
    font-size: 25px;
    color: black !important;
    text-align: start;
  }
  .row-cardHistoria h6 {
    font-size: 17px;
  }
  .row-cardHistoria h4 {
    text-align: start;
    font-size: 17px !important;
  }

  .displayCono {
    display: block;
  }
  .col-timeConon {
    display: none;
  }
}
