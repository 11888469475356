.cardTransparencia,
.cardTransparencia-2 {
  height: auto;
  /* min-width: 40vw; */
  position: relative;

  height: 125px !important;
  background: gray !important;
  border: 1px solid black !important;
  margin-bottom: 2vh !important;
  padding-left: 5%;
  padding-right: 5%;
}

.cardTransparencia .imgPag1 {
  width: 150px;
  height: 100%;
}
.cardTransparencia-2 .imgPag1 {
  width: 150px;
  height: 100%;
  margin-left: 75%;
}
.cardTransparencia-2 .h2pag1 {
  position: relative !important;
  bottom: 7.5vh;
  font-size: 2.4rem !important;
  color: rgb(255, 255, 255);
  line-height: 5vh;
}
.cardTransparencia .h2pag1 {
  position: relative !important;
  margin-left: 30%;
  bottom: 7.5vh;
  font-size: 2.4rem;

  color: rgb(255, 255, 255);
  line-height: 5vh;
}
.enlaceTransparencia {
  text-decoration: none;
}
