@media screen and (max-width: 1150px) {
  * {
    margin: 0%;
    padding: 0%;
  }

  .liBotonAtras {
    display: none;
  }

  #menu1,
  .menu2 {
    display: none;
    list-style: none;
    width: 100vw;
    margin-top: 15px;
  }

  li {
    text-decoration: none;
    color: black;
    font-weight: bold;
  }
  nav label {
    margin-bottom: 15px;
  }
  nav a {
    text-decoration: none;
    color: black;
    font-weight: bold;
  }

  nav {
    display: flex;

    background-color: white;
    height: auto;

    flex-direction: column;
  }
  nav ul {
    margin-bottom: 10px;
  }
  nav ul li {
    background-color: white;
    border-top: 1px solid black;
    width: 100% !important;
    padding: 15px 0px;
    text-align: center;
  }

  input:checked ~ #menu1 {
    display: block;
    padding: 0%;
  }

  nav label {
    color: rgb(0, 0, 0);
    position: relative;

    top: 50%;
    transform: translateY(20%);
  }

  #menu1 li:hover > .menu2 {
    position: relative;
    transform: translate(-33px, 24px);

    display: block;
  }

  .menu2 li {
    background-color: rgb(167, 171, 175);
    min-width: 100vw;
  }

  nav ul li:hover {
    background-color: rgb(182, 182, 208);
  }

  nav label {
    box-sizing: border-box;
    display: block;
    border-radius: 5px;
    width: 30px;
    height: 30px;
    font-size: 40px;
    line-height: 30px;
    font-weight: bold;
    text-align: center;
    position: relative;
    left: 90%;
  }

  nav input {
    display: none;
  }
  .BotonesIdiomas {
    display: none;
  }
  .BotonesIdiomasMoviles {
    position: absolute;
    top: 6%;
    left: 5%;
  }
  .fi {
    background-size: contain;
    transform: scale(1.7);
    border: none;
    margin: 0px 10px;
  }
}

@media screen and (min-width: 1150px) {
  * {
    margin: 0%;
    padding: 0%;
  }
  .BotonesIdiomasMoviles {
    display: none;
  }
  #menu1 {
    display: flex;
    justify-content: right;
  }

  nav ul li {
    list-style: none;
    padding: 15px 15px;
    background-color: white;
    width: 250px;
    text-align: left !important;
  }

  nav ul li {
    text-decoration: none;
    color: black;
    font-weight: bold;
    font-size: 20px;
    text-align: center;
  }

  nav a {
    text-decoration: none;
    color: black;
    font-weight: bold;
  }

  .menu2,
  #menu,
  nav label {
    display: none;
  }

  #menu1 li:hover > .menu2 {
    display: block;
    position: absolute;

    transform: translate(-48px, 15px);
    z-index: 3;
    /* para que sobre salga sobre el video de la paguina principal*/
  }
  .fi {
    background-size: contain;
    transform: scale(1.2);
    border: none;
    margin: 0px 10px;
  }
}

nav li:hover {
  color: rgb(40, 90, 159) !important;
}
nav li {
  z-index: 2;
}
ul {
  margin-bottom: 0%;
  z-index: 103;
}

.Principal {
  margin-top: 20px;
}

@media screen and (max-width: 300px) {
  nav label {
    left: 85%;
  }
}
nav,
ul {
  margin: 0% !important;
}
