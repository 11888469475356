.divTitleIconos {
  width: 100%;
  padding-bottom: 5%;
}
.iconsTestTitle{
  color: gray;
  font-size: 65px;
  text-align: center;
  font-family: "Libre Franklin" !important;
}
.card-body-Portada p {
  font-weight: bold;
  font-size: 14px;
  text-align: center;
}

.mi-tarjeta {
  background-color: transparent !important;
  max-width: 90%;
  min-height: 200px !important;
  transition: 0.7s;
  border: 0 !important;
}

.card-body-Portada {
  max-height: 100px;
}

.divImagenIcono {
  display: flex;
  align-items: center;
  justify-content: center;
}
.card-img-top-Portada {
  min-width: 100px;
  height: 100px;
}



@media screen and (max-width: 815px) {
  .mi-tarjeta {
    max-width: 100%;
    display: flex;
    align-items: center;
  }
  .card-img-top-Portada {
    max-height: 10rem;
    max-width: 60%;
  }
  .colIcono {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .card-title p {
    font-size: 18px;
    font-weight: bold;
  }
}
@media screen and (max-width: 991px) {
  .mi-tarjeta {
    max-width: 90%;
    min-height: 20rem;
    display: flex;
    align-items: center;
  }
  .card-img-top-Portada {
    max-height: 12rem;
    max-width: 60%;
  }
  .colIcono {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .card-title p {
    font-size: 18px;
    padding-top: 10px;
    font-weight: bold;
  }
  .iconsTestTitle{
    font-size: 40px !important;
  }
}
@media screen and (max-width: 1451px) {
  .mi-tarjeta {
    max-width: 90%;
    min-height: 20rem;
    display: flex;
    align-items: center;
  }
  .card-img-top-Portada {
    max-height: 12rem;
    max-width: 60%;
  }
  .colIcono {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .card-title p {
    font-size: 18px;
    padding-top: 10px;
    font-weight: bold;
  }
  .iconsTestTitle{
    font-size: 55px;
  }
}
@media screen and (min-width: 1452px) {
  .mi-tarjeta {
    max-width: 100%;
    min-height: 20rem;
    display: flex;
    align-items: center;
  }
  .card-body-Portada p {
    font-size: 20px;
    padding-top: 10px;
    font-weight: bold;
  }
  .card-img-top-Portada {
    min-width: 180px;
    height: 180px;
  }
  .colIcono {
    display: flex;
    align-items: center;
    justify-content: center;
  }
 
}

@media screen and (max-width: 545px) {
  .iconsTestTitle{
    font-size: 30px !important;
  }
}
