.titleSection2 {
  -webkit-text-stroke: 1px #285a9f;
  -webkit-text-stroke-width: 2px;
  color: white;
  font-size: 80px;
  text-align: left;
}
.titleSection1 {
  color: #285a9f;
  font-size: 80px;
  font-weight: 700;
  text-align: left;
  padding-top: 5%;
}
.titleSection .titleSectionBlue {
  color: #285a9f;
}
.subtitleSection {
  padding-top: 10%;
  padding-bottom: 10%;
  font-size: 25px;
}
.textSection {
  padding-top: 5%;
  text-align: justify;
}
@media screen and (max-width: 1400px) {
  .titleSection1 {
    font-size: 60px;
  }
  .titleSection2 {
    font-size: 60px;
  }
  .subtitleSection {
    font-size: 20px;
    text-align: center;
  }
}
@media screen and (max-width: 1000px) {
  .titleSection1 {
    font-size: 55px;
  }
  .titleSection2 {
    font-size: 55px;
  }
  .subtitleSection {
    font-size: 20px;
  }
}
@media screen and (max-width: 1050px) {
  .titleSection1 {
    font-size: 50px;
  }
}
@media screen and (max-width: 850px) {
  .titleSection1 {
    padding-top: 3%;
    font-size: 50px;
  }
  .titleSection2 {
    font-size: 50px;
  }
  .subtitleSection {
    font-size: 15px;
  }
}
@media screen and (min-width: 1750px) {
  .titleSection1 {
    font-size: 110px;
  }
  .titleSection2 {
    font-size: 110px;
  }
}
@media screen and (max-width: 700px) {
  .titleSection1 {
    font-size: 40px !important;
  }
  .titleSection2 {
    font-size: 40px !important;
    -webkit-text-stroke: 0.5px #285a9f;
    -webkit-text-stroke-width: 1px;
  }
}
@media screen and (max-width: 700px) {
  .titleSection1 {
    font-size: 35px !important;
  }
  .titleSection2 {
    font-size: 35waawpx !important;
    -webkit-text-stroke: 0.5px #285a9f;
    -webkit-text-stroke-width: 1px;
  }
}

@media screen and (max-width: 575px) {
  .titleSection1 {
    font-size: 50px !important;
  }
  .titleSection2 {
    font-size: 50px !important;
    -webkit-text-stroke: 0.5px #285a9f;
    -webkit-text-stroke-width: 1px;
  }
  .subtitleSection {
    font-size: 24px;
  }
  .TituloCaracteristicasConocenos {
    font-size: 45px !important;
    text-align: center;
  }
}
